<template>
	<div class=" p-2 m-2 charts-container" :data-ratio="current().allRoi">
		<div class="d-flex flex-row align-items-center" style="min-width:300px;">
			<div style="flex-shrink:0;" class="mr-2 font-weight-bold">{{title}}</div>
			<template>
				<el-popover width="160" trigger="hover">
					<div>Cost: {{current().allCost}}</div>
					<div>Rev: {{current().allRev}}</div>
					<div v-if="!isROI">Ratio: {{current().allRatio}}</div>
					<div v-else>Roi: {{current().allRoi}}</div>
					<div slot="reference" style="font-size:0.9rem;" class="mr-2 font-weight-bold">
						{{current().allCost}}
						/
						{{current().allRev}}
						/
						<span v-if="!isROI">{{current().allRatio}}</span>
						<span v-else>{{current().allRoi}}</span>
					</div>
				</el-popover>
			</template>
			<template>
				<el-popover width="160" trigger="hover">
					<div>Cost0: {{pervious().allCost}}</div>
					<div>Rev0: {{pervious().allRev}}</div>
					<div v-if="!isROI">Ratio0: {{pervious().allRatio}}</div>
					<div v-else>Roi0: {{pervious().allRoi}}</div>
					<div slot="reference" style="font-size:0.9rem;" class="font-weight-bold">
					{{pervious().allCost}}
					/
					{{pervious().allRev}}
					/
					<span v-if="!isROI">{{pervious().allRatio}}</span>
					<span v-else>{{pervious().allRoi}}</span>
					</div>
				</el-popover>
			</template>
		</div>
		<div>
			<el-button v-for="(item,index) in availableDaliyLines" class="charts-box-button" type="text"
				:style="`color:${getcColors(index)};`" @click="$emit('updateDailyLine', item.key, title)"
				:key="`${item.key}-${getcColors(index)}`">
				{{item.label}}
			</el-button>
		</div>
		<div class="charts-box border">
			<div :id="chartId" class="charts" :style="customStyle"></div>
			<div :id="'dataview-' + id" class="data-view-for-charts" style="display:none;"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts/core'
	import {
		TooltipComponent,
		GridComponent,
		ToolboxComponent
	} from 'echarts/components'
	import {
		LineChart
	} from 'echarts/charts'
	import {
		UniversalTransition
	} from 'echarts/features'
	import {
		CanvasRenderer
	} from 'echarts/renderers'
	echarts.use([
		TooltipComponent,
		GridComponent,
		LineChart,
		CanvasRenderer,
		UniversalTransition,
		ToolboxComponent
	]);
	import {
		mapGetters
	} from 'vuex'
	import { uuid } from 'vue-uuid'
	import Moment from 'moment'
	import $Utils from '@/common/util'
	import Common from '@/common/mixins/common.js'
	export default {
		mixins: [Common],
		props: {
			item: {
				type: Object,
				default: () => {}
			},
			dailyLine: {
				type: String,
				default: ''
			},
			isROI: {
				type: Boolean,
				default: true
			},
			isNet: {
				type: Boolean,
				default: true
			},
			timeGroup: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				option: {},
				customStyle: '',
				id: uuid.v4(),
				drawing: false
			}
		},
		computed: {
			...mapGetters([
				'daliyLines',
				'timeGroups'
			]),
			availableDaliyLines() {
				return Object.values(this.daliyLines).filter(v => v.key !== this.dailyLine)
			},
			chartId() {
				return `dataset-${this.id}`
			},
			title() {
				return this.item && this.item.title
			},
			sourceData() {
				const data = this.item && this.item.data || []
				const timeGroup = this.timeGroups.find(v => v.key === this.timeGroup)
				if (timeGroup && timeGroup.key !== 'DAY') {
					const {format} = timeGroup
					// 聚合
					return data.reduce((lhs, rhs) => {
						const formattedDate = Moment(rhs.date).format(format)
						const previous = lhs.find(v => v.formattedDate === formattedDate)
						if (previous !== undefined) {
							Object.keys(rhs).forEach(key => {
								if (key === 'date') {
									previous.dates.push(rhs[key])
								} else if (key !== 'field') {
									previous[key] += rhs[key]
								} 
							})
						} else {
							lhs.push({
								...rhs,
								dates: [rhs.date],
								formattedDate
							})
						}
						return lhs
					}, []).map(v => {
						const dates = v.dates
						const startDate = dates[0]
						const endDate = dates[dates.length - 1]
						return {
							...v,
							date: `${startDate}~${endDate}`
						}
					})
				}
				return data
			},
			date() {
				return this.sourceData.map(v => v.date)
			},
			rev() {
				return this.sourceData.map(v => Math.round(v.revenue * 100) / 100)
			},
			rev0() {
				return this.sourceData.map(v => Math.round(v.compareRevenue * 100) / 100)
			}
		},
		mounted() {
			this.drawChart()
		},
		unmounted() {
			this.clearChart(this.id)
		},
		watch: {
			option(newVal, oldVal) {
				const myChart = echarts.getInstanceByDom(document.getElementById(this.chartId))
				if (myChart) {
					myChart.setOption(newVal)
				}
			},
			item: {
				immediate: true,
				handler: 'drawChart'
			},
			isROI: {
				immediate: true,
				handler: 'drawChart'
			},
			isNet: {
				immediate: true,
				handler: 'drawChart'
			},
			timeGroup: {
				immediate: true,
				handler: 'drawChart'
			}
		},
		methods: {
			cost() {
				if (this.isNet) {
					return this.sourceData.map(v => Math.round(v.netCost * 100) / 100)
				}
				return this.sourceData.map(v => Math.round(v.cost * 100) / 100)
			},
			cost0() {
				if (this.isNet) {
					return this.sourceData.map(v => Math.round(v.compareNetCost * 100) / 100)
				}
				return this.sourceData.map(v => Math.round(v.compareCost * 100) / 100)
			},
			current() {
				const allCost = this.cost().reduce((l, r) => l + r, 0)
				const allRev = this.rev.reduce((l, r) => l + r, 0)
				const v = allRev / allCost
				const allRoi = isNaN(v) || v === Infinity ? 0 : v.toFixed(2)
				const k = allCost / allRev
				const allRatio = isNaN(k) || k === Infinity ? 0 : k.toFixed(2)
				return {
					allCost: allCost.toFixed(0), 
					allRev: allRev.toFixed(0), 
					allRatio, 
					allRoi
				}
			},
			pervious() {
				const allCost = this.cost0().reduce((l, r) => l + r, 0)
				const allRev = this.rev0.reduce((l, r) => l + r, 0)
				const v = allRev / allCost
				const allRoi = isNaN(v) || v === Infinity ? 0 : v.toFixed(2)
				const k = allCost / allRev
				const allRatio = isNaN(k) || k === Infinity ? 0 : k.toFixed(2)
				return {
					allCost: allCost.toFixed(0), 
					allRev: allRev.toFixed(0), 
					allRatio,
					allRoi
				}
			},
			ratio() {
				return this.sourceData.map(v => {
					var k = (this.isNet ? v.netCost : v.cost) / v.revenue
					return isNaN(k) || k === Infinity ? 0 : k.toFixed(2)
				})
			},
			ratio0() {
				return this.sourceData.map(v => {
					var k = (this.isNet ? v.compareNetCost : v.compareCost) / v.compareRevenue
					return isNaN(k) || k === Infinity ? 0 : k.toFixed(2)
				})
			},
			roi() {
				return this.sourceData.map(v => {
					var k = v.revenue / (this.isNet ? v.netCost : v.cost)
					return isNaN(k) || k === Infinity ? 0 : k.toFixed(2)
				})
			},
			roi0() {
				return this.sourceData.map(v => {
					var k = v.compareRevenue / (this.isNet ? v.compareNetCost : v.compareCost)
					return isNaN(k) || k === Infinity ? 0 : k.toFixed(2)
				})
			},
			clearChart() {
				var myChart = null
				try {myChart = echarts.getInstanceByDom(document.getElementById(this.chartId))} catch (e) {}
				if (myChart) {
					myChart.clear()
					myChart.dispose()
				}
				const el = document.querySelector('#dataview-' + this.id)
				if (el) {
					el.style.display = 'none'
				}
			},
			getcColors(index) {
				switch (this.dailyLine) {
					case 'WEBSITE':
					case 'COUNTRY':
						const colors1 = ['blur', 'orange', 'purple']
						return colors1[index]
					case 'CHANNEL':
					case 'CATEGORY':
						const colors2 = ['orange', 'blue', 'purple']
						return colors2[index]
					default:
						return ''
				}
			},
			async buildOptions() {
				return new Promise((resolve, reject) => {
					const date = this.date
					const cost = this.cost()
					const rev = this.rev
					const ratio = this.ratio()
					const cost0 = this.cost0()
					const rev0 = this.rev0
					const ratio0 = this.ratio0()
					const roi = this.roi()
					const roi0 = this.roi0()
					const lastDate = date[date.length - 1]
					const maxLeft = Math.max(...cost, ...cost0, ...rev, ...rev0)
					const maxRight = this.isROI ?  Math.max(...roi, ...roi0) : Math.max(...ratio, ...ratio0)
					const splitNumber = 6
					var leftInterval = $Utils.ceilIntervalNumber(maxLeft / splitNumber, maxLeft / splitNumber < 1 ? 0.1 : 1)
					var rightInterval = $Utils.ceilIntervalNumber(maxRight / splitNumber, 0.1)
					const that = this
					const option = {
						toolbox: {
							show: true,
							showTitle: false,
							feature: {
								saveAsImage: {
									 pixelRatio: 2
								},
								myMore: {
									show: true,
									icon: `path://M176 1024h672c26.464 0 48-21.536 48-48v-672c0-0.544-0.256-0.992-0.288-1.504-0.064-0.736-0.256-1.376-0.416-2.08a15.616 15.616 0 0 0-2.88-6.112c-0.224-0.288-0.192-0.64-0.416-0.928l-256-288c-0.096-0.096-0.224-0.096-0.32-0.192a15.808 15.808 0 0 0-7.136-4.288c-0.608-0.192-1.152-0.256-1.792-0.352C625.824 0.384 624.96 0 624 0h-448C149.536 0 128 21.536 128 48v928c0 26.464 21.536 48 48 48zM640 58.08L844.384 288H656c-7.04 0-16-13.44-16-24V58.08zM160 48a16 16 0 0 1 16-16H608v232c0 25.888 20.96 56 48 56H864v656a16 16 0 0 1-16 16h-672c-8.96 0-16-7.04-16-16v-928z;path://M304 448h416a16 16 0 0 0 0-32h-416a16 16 0 0 0 0 32zM304 576h416a16 16 0 0 0 0-32h-416a16 16 0 0 0 0 32zM304 320h192a16 16 0 0 0 0-32h-192a16 16 0 0 0 0 32zM304 704h416a16 16 0 0 0 0-32h-416a16 16 0 0 0 0 32zM304 832h416a16 16 0 0 0 0-32h-416a16 16 0 0 0 0 32z`,
									title: '数据',
									onclick({option}) {
										const {xAxis, series} = option
										const axisData = xAxis[0].data
										const dateCol = '<td><table border cellspacing="0"><tr><th>Date</th></tr>' + axisData.map(s => `<tr><td>${s}</td></tr>`).join('') + '</table></td>'
										var otherCols = series.map(s => {
											return '<td><table border cellspacing="0">'+ `<tr><th>${s.name}</th></tr>` + s.data.map(d => `<tr><td>${d}</td></tr>`).join('') + '</table></td>'
										})
										const exportCsvId = '#export-csv-' + that.id
										const dataViewId = '#dataview-' + that.id
										const el = document.querySelector(dataViewId)
										const val = JSON.stringify({xAxis, series})
										const closeBtn = `<div class="mb-2 text-right"><button id="export-csv-data-${that.id}" class="el-button el-button--text el-button--mini text-success" data-value='${val}'>导出</button><button class="el-button el-button--text el-button--mini" onclick="document.querySelector('${dataViewId}').style.display='none';">关闭</button></div>`
										el.innerHTML = closeBtn + '<div class="chart-data-table-container"><div><table class="chart-data-table" cellspacing="0"><tr>' + dateCol + otherCols.join('') + '</tr></table></div></div>'
										el.style.display = 'flex'
										document.querySelector(`#export-csv-data-${that.id}`).addEventListener('click', that.exportCsv)
									}
								}
							}
						},
						color: ['#ff0000', '#2000c0', '#ffa500', '#00acff', '#008000', '#800090'],
						xAxis: [{
							type: "category",
							axisLine: {
								onZero: false
							},
							data: date,
							axisTick: {
								show: false,
								dataView: {
									show: true,
									readOnly: true,
								}
							},
							axisLabel: {
								formatter: (_, index) => {
									return date.length - index - 1
								}
							}
						}],
						yAxis: [{
								type: "value",
								scale: false,
								axisLine: {
									show: false
								},
								splitNumber,
								interval: leftInterval,
								max: splitNumber * leftInterval
							},
							{
								type: "value",
								scale: false,
								axisLine: {
									show: false
								},
								splitNumber,
								interval: rightInterval,
								max: splitNumber * rightInterval,
								axisLabel: {
									formatter: (value) => {
										return value.toFixed(1)
									}
								}
							}
						],
						series: [{
								name: "Cost",
								type: "line",
								data: cost
							},
							{
								name: "Rev",
								type: "line",
								data: rev
							},
							{
								name: this.isROI ? 'Roi' : 'Ratio',
								type: "line",
								data: this.isROI ? roi : ratio,
								yAxisIndex: 1
							},
							{
								name: "Cost0",
								type: "line",
								data: cost0
							},
							{
								type: "line",
								name: "Rev0",
								data: rev0
							},
							{
								type: "line",
								name: this.isROI ? 'Roi0' : 'Ratio0',
								data: this.isROI ? roi0 : ratio0,
								yAxisIndex: 1
							}
						],
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: 'cross',
								animation: false,
								label: {
									backgroundColor: '#505765'
								}
							},
							appendToBody: true
						},
						grid: {
							bottom: 30,
							top: 40,
							right: 60,
							left: 60
						}
					}
					resolve(option)
				})
			},
			drawChart() {
				if (this.drawing) return
				this.drawing = true
				this.clearChart()
				this.$nextTick(() => {
					this.customStyle = `width:${40 * this.date.length}px;height:248px;margin:0 20px;`
					this.$nextTick(() => {
						echarts.init(document.getElementById(this.chartId))
						this.buildOptions().then(option => this.option = option)
						this.drawing = false
					})
				})
			},
			exportCsv(el) {
				const val = el.target.getAttribute('data-value')
				const {series, xAxis} = JSON.parse(val)
				const dates = xAxis[0].data
				const rows = dates.map((date, index) => {
					return {
						Date: date,
						...series.reduce((lhs, rhs) => {
							const {name, data} = rhs
							lhs[name] = Number(data[index])
							return lhs
						}, {})
					}
				})
				const title = `Charts Data(${xAxis[0].data[0]}~${xAxis[0].data[xAxis[0].data.length -1]})`
				const payload = {
					status: 'FINISH',
					title,
					content: [{rows, title: this.title}],
					showBadge: true
				}
				this.$store.commit('download-manager/addTask', payload)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.charts-container {
		width: max-content;
		height: max-content;
		.charts-box-button {
			width: 100px;
			text-align: left;
		}
		.charts-box {
			width: max-content;
			height: max-content;
			position: relative;
			.charts {
				min-width: 400px;
				min-height: 250px;
				width: 100%;
				height: 100%;
			}
			.el-button {
				position: absolute;
				right: 10px;
				top: 0;
				z-index: 500;
			}
		}
		:deep(.data-view-for-charts) {
			flex-direction: column;
			position:absolute;
			left:0;
			top:0;
			width:100%;
			height:100%;
			background:#fff;
			padding: 10px;
			overflow: hidden;
			table {
				font-size:12px;
				text-align:center;
				min-width: max-content;
				width: 100%;
				box-sizing: content-box;
			}
			.chart-data-table-container {
				overflow: auto;
				flex: 1;
				height: 100%;
				.chart-data-table>tbody>tr>td {
					padding: 0;
				}
			}
		}
	}
</style>
