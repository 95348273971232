<template>
	<main-container id="dataset-container" :showHeader="false">
		<el-card>
			<advanced-search-bar class="daliyline-box" wrap-component="">
				<template>
					<el-button v-for="daliyLine in daliyLines" :type="queryForm.groupType === daliyLine.key ? 'primary' : ''"
						@click="changeDailyLine(daliyLine.key)" size="mini">{{daliyLine.value}}</el-button>
					<el-popover placement="bottom" width="200" trigger="click">
						<div>
							<h6>其他配置</h6>
							<div class="d-flex flex-row align-content-center justify-content-between">
								Show ROI: <el-checkbox v-model="isROI"></el-checkbox>
							</div>
							<div class="d-flex flex-row align-content-center justify-content-between">
								After Discount: <el-checkbox v-model="isNet"></el-checkbox>
							</div>
							<hr>
							<h6>工具</h6>
							<div class="d-flex flex-row align-items-center justify-content-between">
								<el-button type="primary" size="mini" @click="exportCsv">导 出</el-button>
								<el-checkbox class="mb-0" v-model="originalData" size="mini">原始数据</el-checkbox>
							</div>
						</div>
						<el-button style="position:absolute;right:0;" slot="reference" icon="el-icon-more" circle
							size="mini"></el-button>
					</el-popover>
				</template>
				<template #advanced>
					<form-item v-if="queryForm.groupType !== 'WEBSITE'" label="站点:" style="width:280px;" key="1">
						<el-select value="" placeholder="请选择" size="mini no-poper" class="w-75" @focus="triggerWebsite"
							:popper-append-to-body="false" empty>
						</el-select>
						<small class="w-25 text-primary ml-1" style="cursor:pointer;"
							@click="triggerWebsite">已选择{{queryForm.websites.length}}个</small>
					</form-item>
					<form-item v-if="queryForm.groupType !== 'COUNTRY'" label="国家:" style="width:400px;" key="2">
						<el-select v-model="queryForm.countryCodeCondition" size="mini" class="w-25 mr-1" filterable>
							<el-option label="包含" value="INCLUDE"></el-option>
							<el-option label="不包含" value="EXCLUDE"></el-option>
						</el-select>
						<el-select value="" size="mini" class="w-50 no-poper" @focus="triggerCountry"
							:popper-append-to-body="false" empty>
						</el-select>
						<small class="w-25 text-primary ml-1" style="cursor:pointer;"
							@click="triggerCountry">已选择{{queryForm.countryCodes.length}}个</small>
					</form-item>
					<form-item v-if="queryForm.groupType !== 'CHANNEL'" label="渠道:" style="width:280px;" key="3">
						<el-select value="" size="mini" class="w-75 no-poper" @focus="triggerChannel"
							:popper-append-to-body="false" empty>
						</el-select>
						<small class="w-25 text-primary ml-1" style="cursor:pointer;"
							@click="triggerChannel">已选择{{queryForm.channels.length}}个</small>
					</form-item>
					<form-item v-if="queryForm.groupType !== 'CATEGORY'" label="品类:" style="width:280px;" key="4">
						<el-select value="" size="mini" class="w-75 no-poper" @focus="triggerCategory"
							:popper-append-to-body="false" empty>
						</el-select>
						<small class="w-25 text-primary ml-1" style="cursor:pointer;"
							@click="triggerCategory">已选择{{queryForm.categoryCodes.length}}个</small>
					</form-item>
					<form-item label="选择日期:" style="width:330px;" key="5">
						<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:250px;" size="mini"
							:clearable="false" :picker-options="pickerOptions" @focus="datePickerFocus">
						</el-date-picker>
					</form-item>
					<form-item label="时间聚合粒度" style="width:200px">
						<el-select v-model="timeGroup" :clearable="false" size="mini">
							<el-option v-for="item in timeGroups" :label="item.label" :value="item.key"></el-option>
						</el-select>
					</form-item>
					<form-item label="其他细分:" style="width:200px">
						<el-select v-model="queryForm.test" clearable size="mini">
							<el-option v-for="other in others" :label="other.value" :value="other.key"></el-option>
						</el-select>
					</form-item>
					<form-item>
						<el-button class="mb-0" type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<div class="charts-list-container">
				<div v-for="charts in chartMatrix" class="charts-list-content">
					<template v-for="(item, index) in charts">
						<custom-chart ref="charts" :item="item" :dailyLine="queryForm.groupType" @updateDailyLine="changeDailyLine"
							:isROI="isROI" :isNet="isNet" :timeGroup="timeGroup"></custom-chart>
					</template>
				</div>
			</div>
			<el-dialog title="选择站点" :visible.sync="visible.website" :before-close="handleClose">
				<el-select v-model="websites" placeholder="请选择" size="mini" filterable multiple class="w-100">
					<el-option v-for="channel in channels" :label="channel.key" :value="channel.value"></el-option>
				</el-select>
				<div slot="footer" class="dialog-footer">
					<el-button @click="visible.website = false">取 消</el-button>
					<el-button type="primary" @click="updateWebsites">确 定</el-button>
				</div>
			</el-dialog>
			<el-dialog title="选择国家" :visible.sync="visible.country" :before-close="handleClose">
				<el-select v-model="countryCodes" size="mini" clearable filterable multiple class="w-100">
					<el-option v-for="country in countries" :label="country.name" :value="country.code"
						:disabled="country.code === 'ALL'">
						<div class="d-flex flex-row align-items-center justify-content-between">
							<div>{{country.name}}</div>
							<div>{{country.code}}</div>
						</div>
					</el-option>
				</el-select>
				<div slot="footer" class="dialog-footer">
					<el-button @click="visible.country = false">取 消</el-button>
					<el-button type="primary" @click="updateCountries">确 定</el-button>
				</div>
			</el-dialog>
			<el-dialog title="选择渠道" :visible.sync="visible.source" :before-close="handleClose">
				<el-select v-model="channels" size="mini" clearable class="w-100" filterable multiple>
					<el-option v-for="source in sources" :label="source" :value="source">
					</el-option>
				</el-select>
				<div slot="footer" class="dialog-footer">
					<el-button @click="visible.source = false">取 消</el-button>
					<el-button type="primary" @click="updateChannels">确 定</el-button>
				</div>
			</el-dialog>
			<el-dialog title="选择品类" :visible.sync="visible.category" :before-close="handleClose">
				<el-select v-model="categoryCodes" size="mini" clearable class="w-100" filterable multiple>
					<el-option v-for="item in categories" :label="item" :value="item"></el-option>
				</el-select>
				<div slot="footer" class="dialog-footer">
					<el-button @click="visible.category = false">取 消</el-button>
					<el-button type="primary" @click="updateCategories">确 定</el-button>
				</div>
			</el-dialog>
		</el-card>
		<div v-show="false" id="dataset-campare">
			<div class="bolder">对比</div>
			<el-radio-group v-model="queryForm.compareType" size="small">
				<el-radio v-for="item in comparisons" :label="item.key">{{item.value}}</el-radio>
			</el-radio-group>
		</div>

	</main-container>
</template>

<script>
	import CustomChart from '@/components/dataset/custom-chart.vue'
	import {
		mapGetters,
		mapState,
		mapActions
	} from 'vuex'
	import Moment from 'moment'
	import Common from '@/common/mixins/common.js'
	export default {
		mixins: [Common],
		inject: ['adminLayout'],
		components: {
			CustomChart
		},
		data() {
			return {
				ariaExpanded: false,
				countryCodes: [],
				channels: [],
				categoryCodes: [],
				websites: [],
				visible: {
					website: false,
					country: false,
					source: false,
					category: false
				},
				pickerOptions: {
					disabledDate(date) {
						return Number(Moment(date).startOf('day').format('x')) > Number(Moment().startOf('day').format(
							'x'))
					},
					shortcuts: [{
						text: 'Last 3 days',
						onClick(picker) {
							picker.$emit('pick', [
								Moment().startOf('day').add(-3, 'days').format('YYYY-MM-DD'),
								Moment().endOf('day').add(-1, 'days').format('YYYY-MM-DD')
							])
						}
					}, {
						text: 'Last 7 days',
						onClick(picker) {
							picker.$emit('pick', [
								Moment().startOf('day').add(-7, 'days').format('YYYY-MM-DD'),
								Moment().endOf('day').add(-1, 'days').format('YYYY-MM-DD')
							])
						}
					}, {
						text: 'Last 14 days',
						onClick(picker) {
							picker.$emit('pick', [
								Moment().startOf('day').add(-14, 'days').format('YYYY-MM-DD'),
								Moment().endOf('day').add(-1, 'days').format('YYYY-MM-DD')
							])
						}
					}, {
						text: 'Last 28 days',
						onClick(picker) {
							picker.$emit('pick', [
								Moment().startOf('day').add(-28, 'days').format('YYYY-MM-DD'),
								Moment().endOf('day').add(-1, 'days').format('YYYY-MM-DD')
							])
						}
					}]
				},
				queryForm: {
					channels: [],
					countryCodes: [],
					categoryCodes: [],
					date: [
						Moment().startOf('day').add(-7, 'days').format('YYYY-MM-DD'),
						Moment().endOf('day').add(-1, 'days').format('YYYY-MM-DD')
					],
					dateRange: 'Last 7 days',
					compareType: 'PREVIOUS',
					countryCodeCondition: 'INCLUDE',
					websites: [],
					test: '',
					groupType: 'WEBSITE'
				},
				q: {},
				chartMatrix: [],
				categories: [],
				lastAbortController: null,
				isROI: false,
				isNet: true,
				originalData: false,
				timeGroup: 'DAY'
			}
		},
		computed: {
			...mapGetters([
				'daliyLines',
				'dateRanges',
				'comparisons',
				'others',
				'timeGroups'
			]),
			...mapState({
				countries: state => state.content.countries,
				sources: state => state.dataset.sources
			})
		},
		watch: {
			lastAbortController(newVal, oldVal) {
				oldVal && oldVal.abort()
			},
			timeGroup(newVal) {
				if (this.chartMatrix.length > 0) {
					this.adminLayout.showLoading()
					setTimeout(() => {
						this.adminLayout.hideLoading()
					}, 2000)
				}
			}
		},
		created() {
			var date = [...this.queryForm.date]
			const {startDate, endDate} = this.$route.query
			if (startDate && endDate && /^\d{4}-\d{2}-\d{2}$/.test(startDate) && /^\d{4}-\d{2}-\d{2}$/.test(endDate) && Moment(startDate).startOf('day') - Moment(endDate).startOf('day') <= 0) {
				date[0] = startDate
				date[1] = endDate
			}
			this.queryForm = {
				...this.queryForm,
				...this.$route.query,
				categoryCodes: (this.$route.query.categoryCodes || '').split(',').filter(v => !!v),
				channels: (this.$route.query.channels || '').split(',').filter(v => !!v),
				countryCodes: (this.$route.query.countryCodes || '').split(',').filter(v => !!v),
				websites: (this.$route.query.websites || '').split(',').filter(v => !!v),
				date
			}
		},
		mounted() {
			this.getAllChannels()
				.then(data => {
					this.channels = Object.keys(data).map(key => {
						return {
							key,
							value: data[key]
						}
					})
				})
			this.getCategories()
				.then(data => {
					this.categories = data
				})
			this.getCountries()
			this.fetchSources()
			this.searchEvent()
		},
		methods: {
			...mapActions([
				'getAllChannels',
				'getCategories',
				'getCountries',
				'fetchSources',
				'fetchCharts'
			]),
			initAbortController() {
				try {
					this.lastAbortController = new AbortController()
				} catch (e) {}
			},
			getCharts() {
				this.lastAbortController = new AbortController()
				const signal = this.lastAbortController && this.lastAbortController.signal
				const params = {
					...this.q
				}
				this.adminLayout.showLoading()
				this.fetchCharts({
						params,
						signal
					})
					.then((data) => {
						this.adminLayout.hideLoading()
						this.chartMatrix = data.map((v) => {
							// ES10（2019）
							// return Object.entries(v).map(([title, data]) => {
							// 	return {
							// 		title,
							// 		data
							// 	}
							// })
							return Object.keys(v).map(title => {
								return {
									title,
									data: v[title]
								}
							})
						})
					})
					.catch(err => {
						this.adminLayout.hideLoading()
						this.$showErrMsg(err)
						console.error(err)
					})
			},
			changeDailyLine(daliyLine, value = undefined) {
				if (value) {
					switch (this.queryForm.groupType) {
						case 'WEBSITE':
							const channel = this.channels.find(v => v.key === value)
							if (channel) {
								this.queryForm.websites = [channel.value]
							}
							break
						case 'COUNTRY':
							const country = this.countries.find(v => v.code === value)
							if (country && country.code !== 'ALL') {
								this.queryForm.countryCodes = [country.code]
							}
							break
						case 'CHANNEL':
							const source = this.sources.find(v => v === value)
							if (source) {
								this.queryForm.channels = [source]
							}
							break
						case 'CATEGORY':
							const category = this.categories.find(v => v === value)
							if (category) {
								this.queryForm.categoryCodes = [category]
							}
							break
					}
				}
				this.queryForm.groupType = daliyLine
				this.searchEvent()
			},
			searchEvent() {
				const q = new Map()
				q.startDate = this.queryForm.date[0]
				q.endDate = this.queryForm.date[1]
				q.groupType = this.queryForm.groupType
				q.compareType = this.queryForm.compareType
				if (q.groupType !== 'WEBSITE') {
					q.websites = this.queryForm.websites
				}
				if (q.groupType !== 'COUNTRY') {
					q.countryCodes = this.queryForm.countryCodes
					q.countryCodeCondition = this.queryForm.countryCodeCondition
				}
				if (q.groupType !== 'CHANNEL') {
					q.channels = this.queryForm.channels
				}
				if (q.groupType !== 'CATEGORY') {
					q.categoryCodes = this.queryForm.categoryCodes
				}
				if (this.queryForm.test !== '') {
					q.test = this.queryForm.test
				}
				this.q = q
				this.getCharts()
				const search = Object.keys(q).map(key => {
					const value = q[key]
					if (Array.isArray(value)) {
						return `${key}=${encodeURIComponent(value.join(','))}`
					}
					return `${key}=${encodeURIComponent(value)}`
				}).join('&')
				history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			},
			triggerWebsite() {
				this.websites = [...this.queryForm.websites]
				this.visible.website = true
			},
			updateWebsites() {
				this.queryForm.websites = [...this.websites]
				this.visible.website = false
			},
			triggerCountry() {
				this.countryCodes = [...this.queryForm.countryCodes]
				this.visible.country = true
			},
			updateCountries() {
				this.queryForm.countryCodes = [...this.countryCodes]
				this.visible.country = false
			},
			triggerChannel() {
				this.channels = [...this.queryForm.channels]
				this.visible.source = true
			},
			updateChannels() {
				this.queryForm.channels = [...this.channels]
				this.visible.source = false
			},
			triggerCategory() {
				this.categoryCodes = [...this.queryForm.categoryCodes]
				this.visible.category = true
			},
			updateCategories() {
				this.queryForm.categoryCodes = [...this.categoryCodes]
				this.visible.category = false
			},
			datePickerFocus() {
				const el = document.querySelector('#dataset-campare')
				const parent = document.querySelector('.el-picker-panel__sidebar')
				if (parent) {
					parent.appendChild(el)
					el.style.display = 'block'
				}
			},
			exportCsv() {
				var filename = ''
				const chartsData = this.$refs.charts.map(({option, title, item}) => {
					if (this.originalData) {
						if (filename === '') {
							const start = item.data[0].date
							const end = item.data[item.data.length - 1].date
							filename = `${start}~${end}`
						}
						return {rows: item.data, title}
					}
					const {xAxis, series} = option
					const dates = xAxis[0].data
					const rows = dates.map((date, index) => {
						return {
							Date: date,
							...series.reduce((lhs, rhs) => {
								const {name, data} = rhs
								lhs[name] = Number(data[index])
								return lhs
							}, {})
						}
					})
					if (filename === '') {
						filename = `${xAxis[0].data[0]}~${xAxis[0].data[xAxis[0].data.length -1]}`
					}
					return {rows, title}
				})
				this.$store.commit('download-manager/addTask', {
					status: 'FINISH',
					content: chartsData,
					title: `Charts Data(${filename})`,
					showBadge: true
				})
			}
		}
	}
</script>

<style lang="scss">
	#dataset-container {
		.main-container-content {
			overflow: hidden;
		}

		.el-card {
			height: 100%;
			display: flex;
			flex-direction: column;

			.el-card__body {
				.daliyline-box {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					flex-shrink: 0;
					position: relative;

					.el-button {
						margin: 0 10px 10px 0;
					}
				}

				height: 100%;
				display: flex;
				flex-direction: column;

				.charts-list-container {
					flex: 1;
					overflow-x: auto;

					.charts-list-content {
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
					}
				}
			}
		}
	}
	#dataset-campare {
		position:absolute;
		left:0;
		bottom:0;
		width:100%;
		padding:8px;
		.el-radio__label {
			font-size: 12px;
		}
	}
</style>
